.sidebar-link {
  display: flex;
  width: 100%;
  padding: 0.7rem 0;
  color: #777d7c;
  text-decoration: none;
  align-items: center;
  padding-left: 20px;
}

.active-menu {
  font-weight: 600;
  color: #9d9cb5;
}
